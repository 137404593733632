import { FetchService } from '@modules/Api';

export class BiomaterialServiceApi extends FetchService {
  public getBiomaterialServices = (query?: GetBiomaterialServices) => {
    return this.request<
      'BIOMATERIAL_SERVICE',
      BiomaterialService[],
      null,
      GetBiomaterialServices
    >('/biomaterial_service', 'GET_BIOMATERIAL_SERVICES', { query });
  };

  public getPropertyHistory = (query: GetPropertyHistory) => {
    return this.request<
      'BIOMATERIAL_SERVICE',
      PropertyHistoryRecord[],
      null,
      GetPropertyHistory
    >('/biomaterial_service_result/property_history', 'GET_PROPERTY_HISTORY', {
      query,
    });
  };

  public sendBiomaterialToClient = (serviceId: number) => {
    return this.request<'BIOMATERIAL_SERVICE'>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_SEND_TO_CLIENT',
      {
        method: 'PUT',
        pathParam: `${serviceId}/send_to_client`,
      },
    );
  };

  public makeDocumentsAndJoin = (appointmentId: number) => {
    return this.request<'BIOMATERIAL_SERVICE'>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_MAKE_DOCS_AND_JOIN',
      {
        method: 'PUT',
        pathParam: `make_documents_and_join/${appointmentId}/`,
      },
    );
  };

  public sendAllBiomaterialsToClient = (appointmentId: number) => {
    return this.request<'BIOMATERIAL_SERVICE'>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_SEND_ALL_TO_CLIENT',
      {
        method: 'PUT',
        pathParam: `send_to_client_by_appointment/${appointmentId}`,
      },
    );
  };

  public uploadFileToAppointment = (
    appointmentId: number,
    params: FormData,
  ) => {
    return this.request<'BIOMATERIAL_SERVICE', BiomaterialService, FormData>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_UPLOAD_FILE_APPOINTMENT',
      {
        method: 'PUT',
        pathParam: `upload_file_to_appointment/${appointmentId}`,
        params,
      },
    );
  };

  public refinalizeBioService = (
    serviceId: number,
    params: ReFinaliseBiomaterialService,
  ) => {
    return this.request<
      'BIOMATERIAL_SERVICE',
      BiomaterialService,
      ReFinaliseBiomaterialService
    >(
      '/biomaterial_service_refinalization',
      'BIOMATERIAL_SERVICE_REFINALIZATION',
      {
        method: 'PUT',
        pathParam: `${serviceId}/`,
        params,
      },
    );
  };

  public uploadFile = (serviceId: number, params: FormData) => {
    return this.request<'BIOMATERIAL_SERVICE', BiomaterialService, FormData>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_UPLOAD_FILE',
      {
        method: 'PUT',
        pathParam: `${serviceId}/upload_file`,
        params,
      },
    );
  };

  public getBiomaterialServiceResult = (bioServiceId: number) => {
    return this.request<
      'BIOMATERIAL_SERVICE',
      BiomaterialServiceResultRecord[]
    >('/biomaterial_service_result', 'GET_BIOMATERIAL_SERVICE_RESULT', {
      method: 'GET',
      pathParam: bioServiceId,
      isQueryAbortReq: true,
    });
  };

  public putBiomaterialServiceResult = (
    bioServiceId: number,
    params: GetBioServiceResult[],
  ) => {
    return this.request<
      'BIOMATERIAL_SERVICE',
      BioServiceResult[],
      GetBioServiceResult
    >('/biomaterial_service_result', 'UPDATE_BIOMATERIAL_SERVICE_RESULT', {
      method: 'PUT',
      pathParam: bioServiceId,
      params,
      isQueryAbortReq: true,
      // delay: 400,
    });
  };

  public bioServiceFormulaCalculation = (bioServiceId: number) => {
    return this.request<'BIOMATERIAL_SERVICE', BioServiceResult[]>(
      '/biomaterial_service_result',
      'BIOMATERIAL_SERVICE_FORMULA_CALCULATION',
      {
        method: 'PUT',
        pathParam: `${bioServiceId}/formula_calculation`,
      },
    );
  };

  public bioServiceCalcDeviation = (bioServiceId: number) => {
    return this.request<'BIOMATERIAL_SERVICE', BioServiceResult[]>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_CALC_DEVIATION',
      {
        method: 'PUT',
        pathParam: `${bioServiceId}/processing`,
      },
    );
  };

  public impossibleToComplete = (bioServiceId: number) => {
    return this.request<'BIOMATERIAL_SERVICE', BiomaterialService>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_IMPOSSIBLE_TO_COMPLETE',
      {
        method: 'PUT',
        pathParam: `${bioServiceId}/impossible_to_complete`,
      },
    );
  };

  public getMakeDocument = (bioServiceId: number) => {
    return this.request<'BIOMATERIAL_SERVICE', BiomaterialService>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_MAKE_DOCUMENT',
      {
        method: 'PUT',
        pathParam: `${bioServiceId}/make_document`,
      },
    );
  };

  public getComplete = (bioServiceId: number) => {
    return this.request<'BIOMATERIAL_SERVICE', BiomaterialService>(
      '/biomaterial_service',
      'BIOMATERIAL_SERVICE_GET_COMPLETE',
      {
        method: 'PUT',
        pathParam: `${bioServiceId}/complete`,
      },
    );
  };
}
