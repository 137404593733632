import { action, makeObservable, observable } from 'mobx';
import { AnalysesService } from './AnalysesService';

type AnalysesServices = {
  primary: AnalysesService;
  secondary: AnalysesService[];
};

export class Analyse {
  @observable public list: AppointmentType[] = [];
  @observable public services: AnalysesServices[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public set = (data: AppointmentType[]) => {
    this.list = data;
  };

  @action
  public add = (data: AppointmentType) => {
    this.list.unshift(data);
  };

  @action
  public addService = (
    primary: ServiceResponse,
    secondary: ServiceResponse,
    biomaterialType: BiomaterialType | null,
    secondaryPackage: PatientPkgServiceService | null,
    primaryPackage: PatientPkgServiceService | null,
  ) => {
    const newPrimaryService = new AnalysesService({
      service: primary,
      biomaterialType,
      packageService: primaryPackage ?? null,
    });
    const newSecondaryService = new AnalysesService({
      service: secondary,
      biomaterialType,
      packageService: secondaryPackage,
    });

    // Ищем группу услуг, к которой относится новый primary service
    const groupedServices = this.services.find(
      (group) => group.primary.service.id === primary.id,
    );

    if (!groupedServices) {
      this.services.push({
        primary: newPrimaryService,
        secondary: [newSecondaryService],
      });
    }
    if (groupedServices) {
      groupedServices.secondary.push(newSecondaryService);
    }
  };

  @action
  public removeService = (id: number) => {
    const services: AnalysesServices[] = [];
    const removed: AnalysesService[] = [];

    for (const group of this.services) {
      const { primary, secondary } = group;

      // Создаём новый массив secondary, исключая удаляемую услугу
      const updatedSecondary = secondary.filter(
        (service) => service.service.id !== id,
      );

      if (primary.service.id === id) {
        // Если primary service должен быть удалён
        removed.push(primary);
      } else {
        // Добавляем оставшиеся secondary и проверяем удалённые
        if (updatedSecondary.length < secondary.length) {
          removed.push(
            ...secondary.filter((service) => service.service.id === id),
          );
        }

        // Если остались secondary, добавляем группу обратно в services
        if (updatedSecondary.length > 0) {
          services.push({ primary, secondary: updatedSecondary });
        }
      }
    }

    this.services = services;
    return removed;
  };

  @action
  public clearServices = () => {
    this.services = [];
  };
}
